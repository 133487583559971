import react from 'react';
import airData from '../airData.js';
import { FaRegSmile, FaRegClock } from 'react-icons/fa';
import Moment from 'react-moment';
import 'moment-timezone';
import ProgressBar from "@ramonak/react-progress-bar";

function Header() {
const dateToFormat = '2022-02-17T12:59-0500';
        return (
            <div>
                <div className="overallStatus">
                    maintaining optimal air quality
                </div>
            </div>
        )

}

export default Header