import React, {Component, useState} from 'react';
import './App.css';
import logo from './images/logo.png';
import { FaTemperatureHigh } from 'react-icons/fa';
import OverallStatus from './components/OverallStatus.js';
import ProgressBar from "@ramonak/react-progress-bar";
import iconHumidity from './images/humidity.png';
import iconAirPressure from './images/pressure.png';
import iconFactory from './images/factory.png';
import iconCarbonDioxide from './images/co2.png';
import iconVOCs from './images/chemical.png';
import iconParticulateMatter from './images/pm.png';
import iconCarbonMonoxide from './images/carbon.png';
import iconNitrogenDioxide from './images/nitrogen.png';
import iconOzone from './images/ozone.png';
import spinner from './images/spinner.svg';
import ProgressBar2 from 'react-bootstrap/ProgressBar'

function convertToF(celsius) {
  return Math.trunc(celsius * 9/5 + 34);
}

class App extends Component {

  constructor(props) {
    super(props);
      this.state = {
      airData: []
          }
  }

  loadData() {
    //Get token
    var urlencoded = new URLSearchParams();
    urlencoded.append("code", "6dc2562cc53046280f566b7afca26eed1369f9b3bb93ee2f");

    var requestOptions = {
      method: 'POST',
      body: urlencoded,
      redirect: 'follow'
    };

    
    fetch("https://api.uhooinc.com/v1/generatetoken", requestOptions)
      .then(response => response.json())
      .then((token) => {
        console.log(token.access_token)

        //Get data
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token.access_token);
          
        var urlencoded = new URLSearchParams();
        urlencoded.append("macAddress", "a810876ece65");
        urlencoded.append("mode", "minute");

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

          fetch("https://api.uhooinc.com/v1/devicedata", requestOptions)
          .then(response => response.json())
          .then(res => {
            console.log(res)
            this.setState({
              airData: res.data
            })
          });
        
      });

  }

  componentWillMount() {
    this.loadData();
  }

  componentDidMount(){
    this.updateTimer = setInterval(() => this.loadData(), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.updateTimer);
  }

  render () {

        return (
          
          <div className="app">
            {this.state.airData.map(res => (
                  <div>
                    <header className="header">
                      <div>
                      <img src={logo} alt="Logo" className="logo" />
                          <div className="temperature">
                          
                              <FaTemperatureHigh /> {convertToF(res.temperature)} F
                              
                          </div>
                      </div>
                  </header>
            <div className="spacer"></div>

                    {/*OVERALL STATUS*/}

                    {res.humidity < 30 ? (
                      <div className="overallStatusAlert">
                        restoring humidity
                      </div>
                    ) : res.humidity > 60 ? (
                      <div className="overallStatusAlert">
                        reducing humidity
                      </div>
                    ) : res.co2 > 1200 ? (
                      <div className="overallStatusAlert">
                        prioritizing ventilation
                      </div>
                    ) : res.pm25 > 50 ? (
                      <div className="overallStatusAlert">
                        prioritizing filtration
                      </div>
                    ) : res.co > 35 ? (
                      <div className="overallStatusAlert">
                        prioritizing ventilation
                      </div>
                    ) : res.tvoc > 400 ? (
                      <div className="overallStatusAlert">
                        prioritizing ventilation
                      </div>
                    ) : res.no2 > 100 ? (
                      <div className="overallStatusAlert">
                          prioritizing ventilation
                      </div>
                    ) : res.ozone > 30 ? (
                      <div className="overallStatusAlert">
                        prioritizing filtration
                      </div>
                    ) : (
                      <div className="overallStatus">
                        maintaining quality air
                      </div>
                    )}

                    

                    

                  {/*humidity*/}
                  {res.humidity < 30 || res.humidity > 60 ? (
                    <div className='qualityFactor'>
                    <div className='qualityFactorIcon'>
                      <img src={spinner} alt="Logo" className="iconSmall" />
                    </div>
                    <div className='qualityFactorText'>
                      <h1>humidity <span className="right-justify">{res.humidity}%</span></h1>
                      <span className='restoringAlert'>
                        restoring humidity
                      </span>
                    </div>
                  </div>
                  ) : (
                    <div className='qualityFactor'>
                      <div className='qualityFactorIcon'>
                        <img src={iconHumidity} alt="Logo" className="iconSmall" />
                      </div>
                      <div className='qualityFactorText'>
                        <h1>humidity <span className="right-justify">{res.humidity} %</span></h1>
                        <ProgressBar2 now={res.humidity} min="30" max="60" className="statusBar2" height="30px" label={"•"} />
                      </div>
                    </div>
                  )}

                  {/*air pressure*/}
                    <div className='qualityFactor'>
                      <div className='qualityFactorIcon'>
                        <img src={iconAirPressure} alt="Logo" className="iconSmall" />
                      </div>
                      <div className='qualityFactorText'>
                        <h1>air pressure <span className="right-justify">{res.airPressure} hPa</span></h1>
                        <ProgressBar2 now={res.airPressure} min="970" max="1030" className="statusBar2" height="30px" label={"•"} />
                      </div>
                    </div>

                    
                  {/*exhaled air*/}
                  {res.co2 > 1200 ? (
                    <div className='qualityFactor'>
                    <div className='qualityFactorIcon'>
                      <img src={spinner} alt="Logo" className="iconSmall" />
                    </div>
                    <div className='qualityFactorText'>
                      <h1>exhaled air <span className="right-justify">{res.co2} ppm</span></h1>
                      <span className='restoringAlert'>
                        prioritizing ventilation
                      </span>
                    </div>
                  </div>
                  ) : (
                    <div className='qualityFactor'>
                      <div className='qualityFactorIcon'>
                        <img src={iconCarbonDioxide} alt="Logo" className="iconSmall" />
                      </div>
                      <div className='qualityFactorText'>
                        <h1>exhaled air <span className="right-justify">{res.co2} ppm</span></h1>
                        <ProgressBar2 now={res.co2} min="400" max="1200" className="statusBar2" height="30px" label={"•"} />
                      </div>
                    </div>
                  )}

                    
                  {/*particles*/}
                  {res.pm25 > 50 ? (
                    <div className='qualityFactor'>
                    <div className='qualityFactorIcon'>
                      <img src={spinner} alt="Logo" className="iconSmall" />
                    </div>
                    <div className='qualityFactorText'>
                      <h1>particles <span className="right-justify">{res.pm25} ug/m3</span></h1>
                      <span className='restoringAlert'>
                        prioritizing filtration
                      </span>
                    </div>
                  </div>
                  ) : (
                    <div className='qualityFactor'>
                      <div className='qualityFactorIcon'>
                        <img src={iconParticulateMatter} alt="Logo" className="iconSmall" />
                      </div>
                      <div className='qualityFactorText'>
                        <h1>particles <span className="right-justify">{res.pm25} ug/m3</span></h1>
                        <ProgressBar2 now={res.pm25} min="0" max="50" className="statusBar2" height="30px" label={"•"} />
                      </div>
                    </div>
                  )}

                    

                  {/*toxins*/}
                  {res.co > 35 ? (
                    <div className='qualityFactor'>
                    <div className='qualityFactorIcon'>
                      <img src={spinner} alt="Logo" className="iconSmall" />
                    </div>
                    <div className='qualityFactorText'>
                      <h1>toxins <span className="right-justify">{res.co} ppm</span></h1>
                      <span className='restoringAlert'>
                        prioritizing ventilation
                      </span>
                    </div>
                  </div>
                  ) : (
                    <div className='qualityFactor'>
                      <div className='qualityFactorIcon'>
                        <img src={iconNitrogenDioxide} alt="Logo" className="iconSmall" />
                      </div>
                      <div className='qualityFactorText'>
                        <h1>toxins <span className="right-justify">{res.co} ppm</span></h1>
                        <ProgressBar completed={res.co}
                        maxCompleted={35} className="statusBar" labelAlignment="center" height="30px" baseBgColor="#8BC34A" isLabelVisible="true" customLabel="•" labelSize="200px" />
                      </div>
                    </div>
                  )}

                    
                  {/*chemicals*/}
                  {res.tvoc > 400 ? (
                    <div className='qualityFactor'>
                    <div className='qualityFactorIcon'>
                      <img src={spinner} alt="Logo" className="iconSmall" />
                    </div>
                    <div className='qualityFactorText'>
                      <h1>chemicals <span className="right-justify">{res.tvoc} ppb</span></h1>
                      <span className='restoringAlert'>
                        prioritizing ventilation
                      </span>
                    </div>
                  </div>
                  ) : (
                    <div className='qualityFactor'>
                      <div className='qualityFactorIcon'>
                        <img src={iconVOCs} alt="Logo" className="iconSmall" />
                      </div>
                      <div className='qualityFactorText'>
                        <h1>chemicals <span className="right-justify">{res.tvoc} ppb</span></h1>
                        <ProgressBar2 now={res.tvoc} min="0" max="400" className="statusBar2" height="30px" label={"•"} />
                      </div>
                    </div>
                  )}

                  {/*pollutants*/}
                  {res.no2 > 100 ? (
                    <div className='qualityFactor'>
                    <div className='qualityFactorIcon'>
                      <img src={spinner} alt="Logo" className="iconSmall" />
                    </div>
                    <div className='qualityFactorText'>
                      <h1>pollutants <span className="right-justify">{res.no2} ppb</span></h1>
                      <span className='restoringAlert'>
                        prioritizing ventilation
                      </span>
                    </div>
                  </div>
                  ) : (
                    <div className='qualityFactor'>
                      <div className='qualityFactorIcon'>
                        <img src={iconFactory} alt="Logo" className="iconSmall" />
                      </div>
                      <div className='qualityFactorText'>
                        <h1>pollutants <span className="right-justify">{res.no2} ppb</span></h1>
                        <ProgressBar completed={res.no2}
                        maxCompleted={100} className="statusBar" labelAlignment="right" height="30px" baseBgColor="#8BC34A" isLabelVisible="true" customLabel="•" labelSize="200px" />
                      </div>
                    </div>
                  )}


                  {/*pollutants*/}
                  {res.ozone > 30 ? (
                    <div className='qualityFactor'>
                    <div className='qualityFactorIcon'>
                      <img src={spinner} alt="Logo" className="iconSmall" />
                    </div>
                    <div className='qualityFactorText'>
                      <h1>environmental pollution <span className="right-justify">{res.ozone} ppb</span></h1>
                      <span className='restoringAlert'>
                        prioritizing filtration
                      </span>
                    </div>
                  </div>
                  ) : (
                    <div className='qualityFactor'>
                      <div className='qualityFactorIcon'>
                        <img src={iconOzone} alt="Logo" className="iconSmall" />
                      </div>
                      <div className='qualityFactorText'>
                        <h1>environmental pollution  <span className="right-justify">{res.ozone} ppb</span></h1>
                        <ProgressBar completed={res.ozone}
                        maxCompleted={30} className="statusBar" labelAlignment="right" height="30px" baseBgColor="#8BC34A" isLabelVisible="true" customLabel="•" labelSize="200px" />
                      </div>
                    </div>
                  )}

                    {res.humidity < 30 ||
                    res.co2 > 1200 ||
                    res.pm25 > 50 ||
                    res.co > 35 ||
                    res.tvoc > 400 ||
                    res.no2 > 100 ||
                    res.ozone > 30
                     ? (
                      <div className='tagLineAlert'>
                        <h1>clean.quality.breath™</h1>
                      </div>
                    ) : (
                      <div className='tagLine'>
                        <h1>clean.quality.breath™</h1>
                      </div>
                    )}
                    

                  </div>
            ))}
            
          </div>
        )
    }
  }
  
  

export default App;
