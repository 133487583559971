import React, { useEffect, useState} from 'react';

const airData = [
    {
        "PM25":"1",
        "Temperature":"78.8",
        "RelativeHumidity":"53.2",
        "AirPressure":"1005.1",
        "TVOC":"63",
        "CO2":"942",
        "CO":"0",
        "Ozone":"7.9",
        "NO2":"11.3",
        "serialNumber":"50ff6b066571525439181887",
        "VirusIndex":3,
        "Timestamp":1644967651,
        "DateTime":"2022-02-15 18:27"
    }
]
export default airData;





